import $ from 'jquery';

export const getUniqToken = success =>
  $.ajax({
    method: 'GET',
    url: '/api/prospects/new',
    success,
  });

export const createProspect = (prospect, token, success) =>
  $.ajax({
    method: 'POST',
    url: '/api/prospects',
    contentType: 'application/json',
    data: JSON.stringify({ prospect, token }),
    success
  });


export const uploadImage = (file_to_upload, token, success) => {
  const data = new FormData();
  data.append('image', file_to_upload);
  data.append('token', token);

  $.ajax({
    method: 'POST',
    url: '/api/prospects/upload_image',
    contentType: false,
    processData: false,
    data,
    success
  });
};

export const removeImage = (image_id, token, success) =>
  $.ajax({
    method: 'DELETE',
    url: `/api/prospects/images/${image_id}?token=${token}`,
    contentType: 'application/json',
    success
  });

import {
  REQUEST_FAQ_TOPICS,
  REQUEST_FAQ_SECTIONS,
  REQUEST_FAQ_ENTITIES,
  REQUEST_FAQ_ENTITIES_SEARCH,
  receiveFAQTopics,
  receiveFAQSections,
  receiveFAQEntities,
} from '../actions/faq_actions';

import {
  fetchTopics,
  fetchSections,
  fetchEntities,
  searchEntities,
} from '../util/faq_api_util';

const FAQMiddleware = ({ dispatch }) => next => (action) => {
  const topicsSuccess = data => dispatch(receiveFAQTopics(data));
  const sectionsSuccess = data => dispatch(receiveFAQSections(data));
  const entitiesSuccess = data => dispatch(receiveFAQEntities(data));

  switch (action.type) {
    case REQUEST_FAQ_TOPICS:
      fetchTopics(topicsSuccess);
      return next(action);
    case REQUEST_FAQ_SECTIONS:
      fetchSections(sectionsSuccess);
      return next(action);
    case REQUEST_FAQ_ENTITIES:
      fetchEntities(entitiesSuccess);
      return next(action);
    case REQUEST_FAQ_ENTITIES_SEARCH:
      searchEntities(entitiesSuccess, action.query);
      return next(action);
    default:
      return next(action);
  }
};

export default FAQMiddleware;

export const GET_UNIQ_PROSPECT_TOKEN = 'GET_UNIQ_PROSPECT_TOKEN';
export const SET_UNIQ_PROSPECT_TOKEN = 'SET_UNIQ_PROSPECT_TOKEN';
export const CREATE_PROSPECT = 'CREATE_PROSPECT';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const getUniqProspectToken = () => ({
  type: GET_UNIQ_PROSPECT_TOKEN,
});

export const setUniqProspectToken = token => ({
  type: SET_UNIQ_PROSPECT_TOKEN,
  token,
});

export const createProspect = prospect => ({
  type: CREATE_PROSPECT,
  prospect,
});

export const uploadImage = data => ({
  type: UPLOAD_IMAGE,
  data,
});

export const removeImage = data => ({
  type: REMOVE_IMAGE,
  data,
});

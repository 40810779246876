import {
  RECEIVE_REVIEW,
  RECEIVE_REVIEWS,
} from '../actions/review_actions';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_REVIEW:
      return [
        ...state,
        action.review
      ];
    case RECEIVE_REVIEWS:
      return action.reviews;
    default:
      return state;
  }
};

export default reducer;

import {
  CREATE_BOOKING,
  REQUEST_BOOKINGS,
  receiveBooking,
  receiveBookings,
} from '../actions/booking_actions';

import {
  createBooking,
  fetchBookings,
} from '../util/booking_api_util';

import { history } from '../helpers/history';

const BookingMiddleware = ({ dispatch }) => next => (action) => {
  const bookingSuccess = (data) => {
    dispatch(receiveBooking(data));
    history.push(`/bookings/${data.id}`);
  };

  const bookingsSuccess = data => dispatch(receiveBookings(data));

  switch (action.type) {
    case CREATE_BOOKING:
      createBooking(action.booking, bookingSuccess);
      return next(action);
    case REQUEST_BOOKINGS:
      fetchBookings(bookingsSuccess);
      return next(action);
    default:
      return next(action);
  }
};

export default BookingMiddleware;

import {
  REQUEST_JOURNALS,
  SEARCH_JOURNALS,
  REQUEST_JOURNAL_DETAIL,
  REQUEST_RELATED_JOURNALS,
  receiveJournals,
  receiveJournalDetail,
  receiveRelatedJournals,
} from '../actions/journal_actions';

import {
  fetchJournals,
  searchJournals,
  fetchJournalDetail,
  fetchRelatedJournals,
} from '../util/journal_api_util';

const JournalMiddleware = ({ dispatch }) => next => (action) => {
  const journalsSuccess = data => dispatch(receiveJournals(data));
  const journalDetailSuccess = data => dispatch(receiveJournalDetail(data));
  const relatedJournalsSuccess = data => dispatch(receiveRelatedJournals(data));
  const journalsSuccessPagination = data => dispatch(receiveJournals(data, true));

  switch (action.type) {
    case REQUEST_JOURNALS:
      fetchJournals(journalsSuccess);
      return next(action);
    case REQUEST_JOURNAL_DETAIL:
      fetchJournalDetail(action.slug, journalDetailSuccess);
      return next(action);
    case REQUEST_RELATED_JOURNALS:
      fetchRelatedJournals(action.slug, relatedJournalsSuccess);
      return next(action);
    case SEARCH_JOURNALS:
      searchJournals(
        action.query,
        action.query.page > 1
          ? journalsSuccessPagination
          : journalsSuccess,
      );
      return next(action);
    default:
      return next(action);
  }
};

export default JournalMiddleware;

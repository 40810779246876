import {
  RECEIVE_JOBS,
  RECEIVE_JOB_DETAIL,
} from '../actions/job_actions';

const initialState = {
  items: [],
  jobDetail: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_JOBS:
      return {
        ...state,
        items: action.jobs,
      };
    case RECEIVE_JOB_DETAIL:
      return {
        ...state,
        jobDetail: action.jobDetail,
      };
    default:
      return state;
  }
};

export default reducer;

import $ from 'jquery';

export const createQueuer = (queuer, success) => {
  $.ajax({
    method: 'POST',
    url: '/api/queuers',
    contentType: 'application/json',
    data: JSON.stringify(queuer),
    success
  });
};

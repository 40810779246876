import {
  receiveErrors,
  LOGIN,
  LOGOUT,
  SIGNUP
} from '../actions/session_actions';

import { requestBookings } from '../actions/booking_actions';

import { requestProperties } from '../actions/listing_actions';

import {
  login,
  signup,
  logout,
} from '../util/session_api_util';

const SessionMiddleware = ({ dispatch }) => next => (action) => {
  const success = () => {
    action.callback();
    dispatch(requestBookings());
    dispatch(requestProperties());
  };

  const error = res => dispatch(receiveErrors(res.responseJSON || res));

  switch (action.type) {
    case LOGIN:
      login(action.user, success, error);
      return next(action);
    case LOGOUT:
      logout(() => next(action));
      return next(action);
    case SIGNUP:
      signup(action.user, success, error);
      return next(action);
    default:
      return next(action);
  }
};

export default SessionMiddleware;

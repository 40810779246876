export const REQUEST_PAGE = 'REQUEST_PAGE';
export const RECEIVE_PAGE = 'RECEIVE_PAGE';

export const requestPage = pageType => ({
  type: REQUEST_PAGE,
  pageType,
});

export const receivePage = page => ({
  type: RECEIVE_PAGE,
  page
});

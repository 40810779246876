import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import { signup } from '../../actions/session_actions';

class SignupForm extends Component {
  state = {
    email: '',
    fname: '',
    lname: '',
    password: '',
    passwordConfirm: '',
    phoneNumber: ''
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const user = { user: this.state };
    const { signup } = this.props;
    signup(user, this.onRedirect);
  }

  onRedirect = () => {
    if (this.props.redirectUrl) {
      this.props.history.push(this.props.redirectUrl);
    }
    this.props.closeModal();
  }

  update = field => e => this.setState({
    [field]: e.currentTarget.value
  })

  getErrorFor = key =>
    get(
      this.props,
      `errors.error.${key}[0]`,
      '',
    );

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      phoneNumber,
    } = this.state;

    return (
      <div className="signup-form-div" >
        <div className="row with-social">
          <a href="/auth/google_oauth2/" className="google-link">
            <div className="google-logo" />
            <p>Sign up with Google</p>
          </a>
          <a href="" className="facebook-link">
            <div className="facebook-logo" />
            <p>Sign up with Facebook</p>
          </a>
        </div>
        <form className="signup-form-box" onSubmit={this.handleSubmit}>
          <div className="input-item input-item--row">
            <div className="input-box input-box--half">
              <input
                type="text"
                value={first_name}
                onChange={this.update('first_name')}
                className="signup-input form-input first"
                placeholder="First name"
              />
              <span className="input-box-error">
                {this.getErrorFor('first_name')}
              </span>
            </div>
            <div className="input-box input-box--half">
              <input
                type="text"
                value={last_name}
                onChange={this.update('last_name')}
                className="signup-input form-input"
                placeholder="Last name"
              />
              <span className="input-box-error">
                {this.getErrorFor('last_name')}
              </span>
            </div>
          </div>
          <div className="input-item row input-item--row">
            <div className="input-box input-box--half">
              <input
                type="text"
                value={email}
                onChange={this.update('email')}
                className="signup-input form-input first"
                placeholder="Email address"
              />
              <span className="input-box-error">
                {this.getErrorFor('email')}
              </span>
            </div>
            <div className="input-box input-box--half">
              <input
                type="text"
                value={phoneNumber}
                onChange={this.update('phoneNumber')}
                className="signup-input form-input"
                placeholder="Phone number"
              />
              <span className="input-box-error">
                {this.getErrorFor('phoneNumber')}
              </span>
            </div>
          </div>
          <div className="input-item">
            <div className="input-box">
              <input
                type="password"
                value={password}
                onChange={this.update('password')}
                className="signup-input form-input"
                placeholder="Password"
              />
              <span className="input-box-error">
                {this.getErrorFor('password')}
              </span>
            </div>
          </div>
          <div className="input-item">
            <input
              type="password"
              value={password_confirmation}
              onChange={this.update('password_confirmation')}
              className="signup-input form-input"
              placeholder="Confirm Password"
            />
            <span className="input-box-error">
              {this.getErrorFor('password_confirmation')}
            </span>
          </div>
          <input
            className="user-submit signup-submit"
            type="submit"
            value="SIGN UP"
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ session, modals }) => ({
  errors: session.errors,
  redirectUrl: modals.redirectUrl,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    signup,
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupForm));

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app';

import ScrollToTop from './scrollToTop';
import Success from './notifications/success';

import { history } from '../helpers/history';

class Fallback extends React.Component {
  render() {
    return (
      <div style={{ height: '100vh', position: 'absolute' }} className="pageLoader pageLoader--visible" >
        <svg viewBox="0 0 39.69 73.99">
          <path d="M39.69,8.07c0-1.42-.22-2.59-1.68-3.39L29.6,0,25.08,1.78l8.58,4.78a2.59,2.59,0,0,1,1.59,2.79c0,14.58.23,28.9,0,43.48-.15,9.32-1.93,12.28-9.9,15.37-6.48,2.51-11.5-1.23-11.48-7.07,0-5.52.05-12.63.05-18.94,0-8.35,0-16.3,0-24.65,0-1.2,0-2.59-1.34-3.29-1.53-.81-6.11-3.38-8-4.43L0,11.63c1.75,1,4.13,2.25,6.76,3.6a4.31,4.31,0,0,1,2.7,4.52c-.1,13.87-.05,27.74-.05,41.6a29,29,0,0,0,.11,3.73C10.31,71,15.43,74.34,21,74a25.08,25.08,0,0,0,9.09-3.12c8.43-4.73,9.57-10.22,9.57-18.66,0-2.57,0-5.15,0-7.73C39.67,32.27,39.66,20.25,39.69,8.07Z" />
          <path d="M17.91,16.37c-.1,13.78-.05,27.56-.05,41.34a18.29,18.29,0,0,0,.08,2.67c.36,2.45,1.93,4,4.24,3.93a13,13,0,0,0,5.17-1.6c3.26-1.59,3.5-3.55,3.5-8.83,0-4.39.15-28.35.15-42.57v-.85a2.46,2.46,0,0,0-1.08-2.25l-9-4.8L16.69,5.07c3,1.65,5.85,3.23,7.77,4.24a3.64,3.64,0,0,1,2.14,3.78c-.07,14,0,38.78,0,41.8,0,1.93.21,2.91-1,3.77-.53.39-2.54.92-2.94-.36a13.7,13.7,0,0,1-.08-2.4q0-20.4,0-40.81V13.83a3,3,0,0,0-1.06-2.47s-5.18-3.11-8.28-4.92L8.5,8.29,15.34,12A4.27,4.27,0,0,1,17.91,16.37Z" />
        </svg>
      </div>
    );
  }
}
const suspend = Cmp => props => <Suspense fallback={<Fallback />}><Cmp {...props} /></Suspense>;

const JournalContainer = React.lazy(() => import('./journal/journal_container'));
const PropertyDetails = React.lazy(() => import('./listing_detail/property_details'));
const ClientSignUpContainer = React.lazy(() => import('./client_signup/client_signup_container'));
const PastBookings = React.lazy(() => import('./booking/past_bookings'));
const BookingList = React.lazy(() => import('./mobile_components/booking_list'));
const PropertyList = React.lazy(() => import('./mobile_components/property_list'));
const GuestService = React.lazy(() => import('./guest_service/guest_service'));
const SearchJ15Container = React.lazy(() => import('./booking/searchJ15_container'));
const ChooseInspection = React.lazy(() => import('./choose_inspection'));
const BookingContainer = React.lazy(() => import('./booking/booking_container'));
const BookingDetail = React.lazy(() => import('./booking/booking_detail'));
const AboutUs = React.lazy(() => import('./about/about_us'));
const Careers = React.lazy(() => import('./careers/careers'));
const JobDetail = React.lazy(() => import('./careers/job_details'));
const Calendar = React.lazy(() => import('./calendar'));
const SearchResults = React.lazy(() => import('./search_results/search_results'));
const FAQ = React.lazy(() => import('./faq/faq'));
const AccountSettings = React.lazy(() => import('./account_settings'));
const Home = React.lazy(() => import('./home/home'));
const CaseStudy = React.lazy(() => import('./client_signup/case_study'));
const SiteMap = React.lazy(() => import('./about/sitemap'));
const Listing = React.lazy(() => import('./listing'));
const Page = React.lazy(() => import('./static_page'));
const Prospect = React.lazy(() => import('./prospect'));

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ScrollToTop>
          <App>
            <Switch>
              <Route component={suspend(SearchResults)} path="/search" />
              <Route component={suspend(PropertyDetails)} path="/properties/:id" />
              <Route component={suspend(BookingDetail)} path="/bookings/:id" />
              <Route component={suspend(PastBookings)} path="/past-bookings" />
              <Route component={suspend(ClientSignUpContainer)} path="/list-your-home" />
              <Route component={suspend(BookingContainer)} path="/booking-home" />
              <Route component={suspend(AboutUs)} path="/about" />
              <Route component={suspend(JobDetail)} path="/careers/:id" />
              <Route component={suspend(Careers)} path="/careers" />
              <Route component={suspend(GuestService)} path="/guest-service" />
              {window.J15 && (
                <Route component={suspend(SearchJ15Container)} path="/enquiry" />
              )}
              <Route component={suspend(CaseStudy)} path="/case-study" />
              <Route component={suspend(JournalContainer)} path="/journal" />
              <Route component={suspend(SiteMap)} path="/sitemap" />
              <Route component={suspend(FAQ)} path="/faq" />
              <Route component={suspend(AccountSettings)} path="/account-settings" />
              <Route component={suspend(BookingList)} path="/booking-list" />
              <Route component={suspend(PropertyList)} path="/property-list" />
              <Route component={suspend(Prospect)} path="/prospect" />
              <Route component={suspend(ChooseInspection)} path="/choose-inspection" />
              <Route component={suspend(Listing)} path="/listings/:id" />
              <Route component={suspend(Calendar)} path="/calendar" />
              <Route
                path="/careers-success"
                render={props => (
                  <Success
                    message="Your application has been received"
                    buttonTitle="Back to overview"
                    {...props}
                  />
                )}
              />
              <Route
                path="/queuer-success"
                render={props => (
                  <Success
                    message="Your preferences have been saved. We will ~ get back to you with matching homes."
                    {...props}
                  />
                )}
              />
              <Route
                path="/guest-success"
                render={props => (
                  <Success
                    message="We will get back to you within 24 hours."
                    buttonTitle="Back to overview"
                    {...props}
                  />
                )}
              />
              <Route
                path="/prospect-success"
                render={props => (
                  <Success
                    message="We look forward to meeting ~ with you soon."
                    {...props}
                  />
                )}
              />
              <Route component={suspend(Page)} path="/cancellation-policy" />
              <Route component={suspend(Page)} path="/terms-and-conditions" />
              <Route component={suspend(Page)} path="/terms-of-use" />
              <Route component={suspend(Page)} path="/privacy-policy" />
              <Route component={suspend(Home)} path="/" />
            </Switch>
          </App>
        </ScrollToTop>
      </Router>
    </PersistGate>
  </Provider>
);

export default Root;

import {
  RECEIVE_ID_INFO_SUCCESS,
  RECEIVE_ID_INFO_FAILURE,
  RESET_ID_INFO,
} from '../actions/idcard_actions';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ID_INFO_SUCCESS:
      return {
        ...state,
        isVerified: true,
        isLoading: false,
        idCardInfo: action.idCardInfo,
      };
    case RECEIVE_ID_INFO_FAILURE:
      return state.MRTD
        ? state
        : {
          ...state,
          isVerified: false,
          isLoading: false,
          idCardInfo: {},
        };
    case RESET_ID_INFO:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;

export const REQUEST_LISTINGS = 'REQUEST_LISTINGS';
export const REUQEST_PROPERTIES = 'REUQEST_PROPERTIES';
export const RECEIVE_LISTINGS = 'RECEIVE_LISTINGS';
export const RECEIVE_PROPERTIES = 'RECEIVE_PROPERTIES';
export const REQUEST_SINGLE_LISTING = 'REQUEST_SINGLE_LISTING';
export const RECEIVE_SINGLE_LISTING = 'RECEIVE_SINGLE_LISTING';
export const CREATE_LISTING = 'CREATE_LISTING';

export const requestListings = (filter, value) => ({
  type: REQUEST_LISTINGS,
  filter,
  value
});

export const receiveListings = listings => ({
  type: RECEIVE_LISTINGS,
  listings
});

export const requestSingleListing = id => ({
  type: REQUEST_SINGLE_LISTING,
  id
});

export const receiveSingleListing = listing => ({
  type: RECEIVE_SINGLE_LISTING,
  listing
});

export const createListing = () => ({
  type: CREATE_LISTING,
});

export const requestProperties = () => ({
  type: REUQEST_PROPERTIES,
});

export const receiveProperties = properties => ({
  type: RECEIVE_PROPERTIES,
  properties,
});


import $ from 'jquery';

import {
  removeToken,
  setToken,
} from './common_util';

export const login = (user, success, error) => {
  $.ajax({
    method: 'POST',
    url: '/api/login',
    data: user,
    success: (data, _, xhrObject) => {
      success(data);
      setToken(xhrObject.getResponseHeader('authorization'));
    },
    error,
  });
};

export const signup = (user, success, error) => {
  $.ajax({
    method: 'POST',
    url: '/api/signup',
    data: user,
    success,
    error
  });
};

export const logout = (success) => {
  $.ajax({
    method: 'DELETE',
    url: '/api/logout',
    success: (...args) => {
      success(args);
      removeToken();
    }
  });
};

export const REQUEST_FAQ_TOPICS = 'REQUEST_FAQ_TOPICS';
export const RECEIVE_FAQ_TOPICS = 'RECEIVE_FAQ_TOPICS';
export const REQUEST_FAQ_SECTIONS = 'REQUEST_FAQ_SECTIONS';
export const RECEIVE_FAQ_SECTIONS = 'RECEIVE_FAQ_SECTIONS';
export const REQUEST_FAQ_ENTITIES = 'REQUEST_FAQ_ENTITIES';
export const REQUEST_FAQ_ENTITIES_SEARCH = 'REQUEST_FAQ_ENTITIES_SEARCH';
export const RECEIVE_FAQ_ENTITIES = 'RECEIVE_FAQ_ENTITIES';

export const requestFAQTopics = () => ({
  type: REQUEST_FAQ_TOPICS,
});

export const receiveFAQTopics = topics => ({
  type: RECEIVE_FAQ_TOPICS,
  topics
});

export const requestFAQSections = () => ({
  type: REQUEST_FAQ_SECTIONS,
});

export const receiveFAQSections = sections => ({
  type: RECEIVE_FAQ_SECTIONS,
  sections
});

export const requestFAQEntities = () => ({
  type: REQUEST_FAQ_ENTITIES,
});

export const requestFAQEntitiesSearch = query => ({
  type: REQUEST_FAQ_ENTITIES_SEARCH,
  query,
});

export const receiveFAQEntities = entities => ({
  type: RECEIVE_FAQ_ENTITIES,
  entities
});

export const OPEN_LOGIN = 'OPEN_LOGIN';
export const OPEN_SIGNUP = 'OPEN_SIGNUP';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const CLOSE_SIGNUP = 'CLOSE_SIGNUP';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';

export const openLogin = redirectUrl => ({
  type: OPEN_LOGIN,
  redirectUrl,
});

export const openSignup = redirectUrl => ({
  type: OPEN_SIGNUP,
  redirectUrl,
});

export const closeLogin = () => ({
  type: CLOSE_LOGIN,
});

export const closeSignup = () => ({
  type: CLOSE_SIGNUP,
});

export const clearRedirect = () => ({
  type: CLEAR_REDIRECT,
});

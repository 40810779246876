import $ from 'jquery';

export const fetchJobs = (success) => {
  $.ajax({
    method: 'GET',
    url: '/api/jobs',
    success,
  });
};


export const fetchJobDetail = (id, success) => {
  $.ajax({
    method: 'GET',
    url: `/api/jobs/${id}`,
    success,
  });
};

export const submitJob = (jobId, details, success, failure) => {
  $.ajax({
    method: 'POST',
    url: `/api/jobs/${jobId}/bids`,
    data: JSON.stringify(details),
    contentType: 'application/json',
    success,
    failure,
  });
};

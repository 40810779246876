import { reset } from 'redux-form';

import {
  CREATE_QUEUER,
} from '../actions/queuer_actions';

import { history } from '../helpers/history';

import { createQueuer } from '../util/queuer_api_util';

const QueuerMiddleware = ({ dispatch }) => next => (action) => {
  const queuerSuccess = () => {
    history.push('/queuer-success');
    dispatch(reset('searchJ15Details'));
  };

  switch (action.type) {
    case CREATE_QUEUER:
      createQueuer(action.queuer, queuerSuccess);
      return next(action);
    default:
      return next(action);
  }
};

export default QueuerMiddleware;

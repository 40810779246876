import {
  RECEIVE_AMENITIES,
  RECEIVE_HIGHLIGHTS,
  RECEIVE_EXTRAS,
} from '../actions/utility_actions';

const initialState = {
  amenities: [],
  highlights: [],
  extras: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_AMENITIES:
      return {
        ...state,
        amenities: action.amenities,
      };
    case RECEIVE_HIGHLIGHTS:
      return {
        ...state,
        highlights: action.highlights,
      };
    case RECEIVE_EXTRAS:
      return {
        ...state,
        extras: action.extras,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { withRouter } from 'react-router-dom';

import '../../style/_success.scss';

class Success extends React.Component {
  handleBack = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { buttonTitle, message } = this.props;
    const sentences = message.split('~');
    return (
      <div className="success-container">
        {buttonTitle && (
          <button className="back-button" onClick={this.handleBack}>
            <i className="fa fa-angle-left" />
            {buttonTitle}
          </button>
        )}
        <h2>Thank you.</h2>
        <div className="messages">
          {
            sentences.map(item => (
              <p>{item}</p>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Success);

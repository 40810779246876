import {
  RECEIVE_FAQ_TOPICS,
  RECEIVE_FAQ_SECTIONS,
  RECEIVE_FAQ_ENTITIES,
} from '../actions/faq_actions';

const initialState = {
  topics: [],
  sections: [],
  entities: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FAQ_TOPICS:
      return {
        ...state,
        topics: action.topics,
      };
    case RECEIVE_FAQ_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
    case RECEIVE_FAQ_ENTITIES:
      return {
        ...state,
        entities: action.entities,
      };
    default:
      return state;
  }
};

export default reducer;

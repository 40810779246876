import $ from 'jquery';

export const fetchListings = (success, filters) => {
  $.ajax({
    method: 'GET',
    url: '/api/listings',
    data: filters,
    success,
    error: () => console.log('error in listing api util#fetchListings')
  });
};

export const fetchListing = (id, success) => {
  $.ajax({
    method: 'GET',
    url: `/api/listings/${id}`,
    success
  });
};

export const createListing = (listing, success) => {
  const formData = new FormData();
  formData.append('listing[user_id]', listing.user_id);
  formData.append('listing[lat]', listing.lat);
  formData.append('listing[lng]', listing.lng);
  formData.append('listing[name]', listing.name);
  formData.append('listing[description]', listing.description);
  formData.append('listing[guests]', listing.guests);
  formData.append('listing[beds]', listing.beds);
  formData.append('listing[price]', listing.price);
  // formData.append("listing[address]", listing.address);
  formData.append('listing[address]', 'Dummy Address');
  formData.append('listing[kind]', listing.kind);
  formData.append('listing[city]', listing.city);
  formData.append('listing[country]', listing.country);
  formData.append('listing[image]', listing.image);

  $.ajax({
    method: 'POST',
    url: '/api/listings',
    processData: false,
    contentType: false,
    data: formData,
    success
  });
};

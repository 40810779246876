import $ from 'jquery';

export const fetchJournals = success =>
  $.ajax({
    method: 'GET',
    url: '/api/journals',
    success,
  });

export const searchJournals = ({
  searchQuery,
  filterQuery,
  page,
}, success) =>
  $.ajax({
    method: 'GET',
    url: '/api/journals/search',
    data: {
      query: searchQuery,
      category_name: filterQuery,
      page,
    },
    success,
  });

export const fetchJournalDetail = (slug, success) =>
  $.ajax({
    method: 'GET',
    url: `/api/journals/${slug}`,
    success,
  });

export const fetchRelatedJournals = (slug, success) =>
  $.ajax({
    method: 'GET',
    url: `/api/journals/${slug}/related_articles`,
    success,
  });

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS';

export const updateFilter = (filter, value) => ({
  type: UPDATE_FILTER,
  filter,
  value,
});

export const updateFilterOptions = (isFavorite, filterOptions) => ({
  type: UPDATE_FILTER_OPTIONS,
  isFavorite,
  filterOptions,
});

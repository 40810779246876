import merge from 'lodash/merge';

import {
  REQUEST_LISTINGS,
  REUQEST_PROPERTIES,
  REQUEST_SINGLE_LISTING,
  CREATE_LISTING,
  receiveListings,
  receiveSingleListing,
  requestListings,
  receiveProperties,
} from '../actions/listing_actions';

import { UPDATE_FILTER } from '../actions/filter_actions';

import {
  fetchListings,
  fetchListing,
  createListing,
} from '../util/listing_api_util';

import { history } from '../helpers/history';

const ListingMiddleware = ({ getState, dispatch }) => next => (action) => {
  const listingsSuccess = data => dispatch(receiveListings(data));
  const propertiesSuccess = data => dispatch(receiveProperties(data));
  const listingSuccess = data => dispatch(receiveSingleListing(data));

  const createSuccess = (data) => {
    dispatch(receiveSingleListing(data));
    history.push(`/listings/${data.id}`);
  };

  switch (action.type) {
    case REQUEST_LISTINGS: {
      const newState = merge({}, getState().filters);
      newState[action.filter] = action.value;
      fetchListings(listingsSuccess, newState);
      return next(action);
    }
    case REUQEST_PROPERTIES:
      fetchListings(propertiesSuccess, null);
      return next(action);
    case UPDATE_FILTER:
      if (action.filter === 'bounds') {
        dispatch(requestListings(action.filter, action.value));
      }
      return next(action);
    case REQUEST_SINGLE_LISTING:
      fetchListing(action.id, listingSuccess);
      return next(action);
    case CREATE_LISTING:
      createListing(getState().listingFields, createSuccess);
      return next(action);
    default:
      return next(action);
  }
};

export default ListingMiddleware;

import {
  REQUEST_PAGE,
  receivePage,
} from '../actions/static_page_actions';

import {
  fetchPage,
} from '../util/static_page_api_util';

const StaticPageMiddleware = ({ dispatch }) => next => (action) => {
  const pageSuccess = data => dispatch(receivePage(data));

  switch (action.type) {
    case REQUEST_PAGE: {
      fetchPage(action.pageType, pageSuccess);
      return next(action);
    }
    default:
      return next(action);
  }
};

export default StaticPageMiddleware;

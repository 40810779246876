import $ from 'jquery';

export const fetchTopics = success =>
  $.ajax({
    method: 'GET',
    url: '/api/faq_topics',
    success,
  });

export const fetchSections = success =>
  $.ajax({
    method: 'GET',
    url: '/api/faq_sections',
    success,
  });

export const fetchEntities = success =>
  $.ajax({
    method: 'GET',
    url: '/api/faq_entities',
    success,
  });

export const searchEntities = (success, query) =>
  $.ajax({
    method: 'GET',
    url: '/api/faq_entities/search',
    data: {
      query,
    },
    success,
  });

import { RECEIVE_PAGE } from '../actions/static_page_actions';

const initialState = { body: null, page_type: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PAGE:
      return {
        ...state,
        ...action.page,
      };
    default:
      return state;
  }
};

export default reducer;

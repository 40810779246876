import { UPDATE_FILTER } from '../actions/filter_actions';

const initialState = {
  bounds: {},
  priceRange: {
    min: 10,
    max: 100000,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER: {
      return {
        ...state,
        [action.filter]: action.value,
      };
    }
    default:
      return state;
  }
};

export default reducer;

export const REQUEST_JOB_DETAIL = 'REQUEST_JOB';
export const RECEIVE_JOB_DETAIL = 'RECEIVE_JOB';
export const REQUEST_JOBS = 'REQUEST_JOBS';
export const RECEIVE_JOBS = 'RECEIVE_JOBS';

export const requestJobDetail = id => ({
  type: REQUEST_JOB_DETAIL,
  id
});

export const receiveJobDetail = jobDetail => ({
  type: RECEIVE_JOB_DETAIL,
  jobDetail
});

export const requestJobs = () => ({
  type: REQUEST_JOBS,
});

export const receiveJobs = jobs => ({
  type: RECEIVE_JOBS,
  jobs
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { login } from '../../actions/session_actions';
import { openSignup } from '../../actions/modal_actions';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login({ user: this.state }, this.onRedirect);
  }

  update = field => e => this.setState({
    [field]: e.currentTarget.value
  })

  guestLogin = () => {
    const { login } = this.props;

    const guest = {
      user: {
        email: 'guest@user.com',
        password: 'guestpass',
      },
    };

    login(guest, this.onRedirect);
  }

  onRedirect = (redirectLink) => {
    const {
      history,
      closeModal,
      redirectUrl,
    } = this.props;

    if (redirectLink) {
      history.push(redirectLink);
    } else if (redirectUrl) {
      history.push(redirectUrl);
    }

    closeModal();
  }

  onOpenSignUp = () => {
    const {
      closeModal,
      openSignup,
      redirectUrl,
    } = this.props;

    closeModal();
    openSignup(redirectUrl);
  }

  getSignUpError = () => {
    const { errors } = this.props;

    return errors.status === 401
      ? (
        <span className="login-form-box-error">
          Invalid email or password.
        </span>
      )
      : '';
  }

  render() {
    const {
      email,
      password,
    } = this.state;

    return (
      <div
        className="login-form-div"
        onSubmit={this.handleSubmit}
      >
        <div className="row with-social">
          <a href="/auth/google_oauth2/" className="google-link">
            <div className="google-logo" />
            <p>Sign in with Google</p>
          </a>
          <a href="/auth/google_oauth2/" className="facebook-link">
            <div className="facebook-logo" />
            <p>Sign in with Facebook</p>
          </a>
        </div>
        {this.getSignUpError()}
        <form className="login-form-box">
          <div className="input-fields-box">
            <div className="input-item">
              <input
                type="text"
                value={email}
                onChange={this.update('email')}
                className="login-input form-input"
                placeholder="Email address"
              />
            </div>
            <div className="input-item">
              <input
                type="password"
                value={password}
                onChange={this.update('password')}
                className="login-input form-input"
                placeholder="Password"
              />
            </div>
          </div>
          <input
            className="user-submit main-login"
            type="submit"
            value="Sign in"
          />
          <div className="links">
            <a onClick={() => this.onRedirect('/forgot-password')}>
              Forgot your password?
            </a>
            <a onClick={this.onOpenSignUp}>
              Don't have an account? Sign up
            </a>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ session, modals }) => ({
  errors: session.errors,
  redirectUrl: modals.redirectUrl
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    login,
    openSignup,
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginForm));

import $ from 'jquery';

export const fetchPage = (pageType, success) => {
  $.ajax({
    method: 'GET',
    url: `/api/pages/${pageType}`,
    success,
    error: () => console.log('error in trend api util#fetchTrends')
  });
};

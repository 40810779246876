export const REQUEST_JOURNALS = 'REQUEST_JOURNALS';
export const RECEIVE_JOURNALS = 'RECEIVE_JOURNALS';
export const SEARCH_JOURNALS = 'SEARCH_JOURNALS';
export const REQUEST_JOURNAL_DETAIL = 'REQUEST_JOURNAL_DETAIL';
export const RECEIVE_JOURNAL_DETAIL = 'RECEIVE_JOURNAL_DETAIL';
export const REQUEST_RELATED_JOURNALS = 'REQUEST_RELATED_JOURNALS';
export const RECEIVE_RELATED_JOURNALS = 'RECEIVE_RELATED_JOURNALS';

export const requestJournals = () => ({
  type: REQUEST_JOURNALS,
});

export const receiveJournals = (journals, withPagination = false) => ({
  type: RECEIVE_JOURNALS,
  journals,
  withPagination,
});

export const searchJournals = query => ({
  type: SEARCH_JOURNALS,
  query,
});

export const requestJournalDetail = slug => ({
  type: REQUEST_JOURNAL_DETAIL,
  slug,
});

export const receiveJournalDetail = journal => ({
  type: RECEIVE_JOURNAL_DETAIL,
  journal
});

export const requestRelatedJournals = slug => ({
  type: REQUEST_RELATED_JOURNALS,
  slug,
});

export const receiveRelatedJournals = journals => ({
  type: RECEIVE_RELATED_JOURNALS,
  journals,
});

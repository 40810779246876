import { UPDATE_LOCATION } from '../actions/location_actions';

const initialState = {
  name: '',
  lat: 0,
  lng: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        ...action.location,
      };
    default:
      return state;
  }
};

export default reducer;

/* eslint-disable */

import indexOf from 'lodash/indexOf';

if (!String.prototype.startsWith) {
  String.prototype.startsWith = (searchString, position) => {
    position = position || 0;
    return indexOf(searchString, position) === position;
  };
}

import {
  OPEN_LOGIN,
  OPEN_SIGNUP,
  CLOSE_LOGIN,
  CLOSE_SIGNUP,
  CLEAR_REDIRECT,
} from '../actions/modal_actions';

const initialState = {
  openLogin: false,
  openSignup: false,
  redirectUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return {
        ...state,
        openLogin: true,
        redirectUrl: action.redirectUrl || null,
      };
    case OPEN_SIGNUP:
      return {
        ...state,
        openSignup: true,
        redirectUrl: action.redirectUrl || null,
      };
    case CLOSE_LOGIN:
      return {
        ...state,
        openLogin: false,
        redirectUrl: null
      };
    case CLOSE_SIGNUP:
      return {
        ...state,
        openSignup: false,
        redirectUrl: null,
      };
    case CLEAR_REDIRECT:
      return {
        ...state,
        redirectUrl: null,
      };
    default:
      return state;
  }
};

export default reducer;

import {
  REQUEST_JOBS,
  REQUEST_JOB_DETAIL,
  receiveJobDetail,
  receiveJobs,
} from '../actions/job_actions';

import {
  fetchJobs,
  fetchJobDetail,
} from '../util/job_api_util';

const JobMiddleware = ({ dispatch }) => next => (action) => {
  const jobDetailSuccess = data => dispatch(receiveJobDetail(data));
  const jobsSuccess = data => dispatch(receiveJobs(data));

  switch (action.type) {
    case REQUEST_JOBS:
      fetchJobs(jobsSuccess);
      return next(action);
    case REQUEST_JOB_DETAIL:
      fetchJobDetail(action.id, jobDetailSuccess);
      return next(action);
    default:
      return next(action);
  }
};

export default JobMiddleware;

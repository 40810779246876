import {
  REQUEST_AMENITIES,
  REQUEST_HIGHLIGHTS,
  REQUEST_EXTRAS,
  receiveHighlights,
  receiveAmenities,
  receiveExtras,
} from '../actions/utility_actions';

import {
  fetchAmenities,
  fetchHighlights,
  fetchExtras,
} from '../util/utility_api_util';

const UtilityMiddleware = ({ dispatch }) => next => (action) => {
  const highlightsSuccess = data => dispatch(receiveHighlights(data));
  const amenitiesSuccess = data => dispatch(receiveAmenities(data));
  const extrasSuccess = data => dispatch(receiveExtras(data));

  switch (action.type) {
    case REQUEST_AMENITIES:
      fetchAmenities(amenitiesSuccess);
      return next(action);
    case REQUEST_HIGHLIGHTS:
      fetchHighlights(highlightsSuccess);
      return next(action);
    case REQUEST_EXTRAS:
      fetchExtras(extrasSuccess);
      return next(action);
    default:
      return next(action);
  }
};

export default UtilityMiddleware;

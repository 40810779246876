import {
  RECEIVE_JOURNALS,
  RECEIVE_JOURNAL_DETAIL,
  RECEIVE_RELATED_JOURNALS,
} from '../actions/journal_actions';

const initialState = {
  items: [],
  journalDetail: {},
  relatedJournals: [],
  totalCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_JOURNALS:
      return {
        ...state,
        items: action.withPagination
          ? [
            ...state.items,
            ...action.journals.journals,
          ]
          : action.journals.journals,
        totalCount: action.journals.total_count,
      };
    case RECEIVE_JOURNAL_DETAIL:
      return {
        ...state,
        journalDetail: action.journal,
      };
    case RECEIVE_RELATED_JOURNALS:
      return {
        ...state,
        relatedJournals: action.journals.journals,
      };
    default:
      return state;
  }
};

export default reducer;

import {
  reset,
  arrayPush,
  arrayRemove,
} from 'redux-form';
import get from 'lodash/get';

import {
  CREATE_PROSPECT,
  UPLOAD_IMAGE,
  REMOVE_IMAGE,
  GET_UNIQ_PROSPECT_TOKEN,
  setUniqProspectToken,
} from '../actions/prospect_actions';

import {
  createProspect,
  uploadImage,
  removeImage,
  getUniqToken,
} from '../util/prospect_api_util';

import { history } from '../helpers/history';

const ProspectMiddleware = ({ dispatch, getState }) => next => (action) => {
  const prospectSuccess = () => {
    history.push('/prospect-success');
    dispatch(reset('ClientSignUp'));
  };

  const uploadSuccess = data =>
    dispatch(arrayPush('ClientSignUp', 'images_ids', data));

  const removeSuccess = index => () =>
    dispatch(arrayRemove('ClientSignUp', 'images_ids', index));

  const uniqTokenSuccess = ({ token }) =>
    dispatch(setUniqProspectToken(token));

  const token = get(getState(), 'prospects.token', '');

  switch (action.type) {
    case GET_UNIQ_PROSPECT_TOKEN:
      getUniqToken(uniqTokenSuccess);
      return next(action);
    case CREATE_PROSPECT:
      createProspect(action.prospect, token, prospectSuccess);
      return next(action);
    case UPLOAD_IMAGE:
      uploadImage(action.data, token, uploadSuccess);
      return next(action);
    case REMOVE_IMAGE:
      removeImage(action.data.id, token, removeSuccess(action.data.index));
      return next(action);
    default:
      return next(action);
  }
};

export default ProspectMiddleware;

import {
  LOGOUT,
  RECEIVE_ERRORS,
  CLEAR_ERRORS,
} from '../actions/session_actions';

const initialState = {
  errors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case RECEIVE_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;

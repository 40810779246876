import {
  SET_UNIQ_PROSPECT_TOKEN,
} from '../actions/prospect_actions';

const initialState = {
  token: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNIQ_PROSPECT_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};

export default reducer;

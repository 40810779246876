import {
  RECEIVE_BOOKING,
  RECEIVE_BOOKINGS,
} from '../actions/booking_actions';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BOOKING:
      return [
        ...state,
        action.booking
      ];
    case RECEIVE_BOOKINGS:
      return action.bookings;
    default:
      return state;
  }
};

export default reducer;

export const REQUEST_HIGHLIGHTS = 'REQUEST_HIGHLIGHTS';
export const RECEIVE_HIGHLIGHTS = 'RECEIVE_HIGHLIGHTS';
export const REQUEST_AMENITIES = 'REQUEST_AMENITIES';
export const RECEIVE_AMENITIES = 'RECEIVE_AMENITIES';
export const REQUEST_EXTRAS = 'REQUEST_EXTRAS';
export const RECEIVE_EXTRAS = 'RECEIVE_EXTRAS';

export const requestHighlights = () => ({
  type: REQUEST_HIGHLIGHTS,
});

export const receiveHighlights = highlights => ({
  type: RECEIVE_HIGHLIGHTS,
  highlights,
});

export const requestAmenities = () => ({
  type: REQUEST_AMENITIES,
});

export const receiveAmenities = amenities => ({
  type: RECEIVE_AMENITIES,
  amenities,
});

export const requestExtras = () => ({
  type: REQUEST_EXTRAS,
});

export const receiveExtras = extras => ({
  type: RECEIVE_EXTRAS,
  extras,
});

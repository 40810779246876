import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import footerLogo from '../../assets/svg/footerlogo.svg';

import '../../style/_footer.scss';

const animationImport = import('../../helpers/animations.js');

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };

    this.footerLogo = null;
    this.footerAbout = null;
    this.footerPolicies = null;
    this.footerSocial = null;
    this.footerContact = null;
  }

  componentDidMount() {
    animationImport.then(({ default: animation }) => {
      animation.show(this.footerLogo, 1.8);
      animation.show(this.footerAbout, 1.8);
      animation.show(this.footerPolicies, 1.83);
      animation.show(this.footerSocial, 1.86);
      animation.show(this.footerContact, 1.89);
    });
  }

  trigger = (index) => {
    if (this.state.selectedIndex === index) {
      this.setState({ selectedIndex: -index });
    } else {
      this.setState({ selectedIndex: index });
    }
  };

  render() {
    const { selectedIndex } = this.state;

    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img
              src={footerLogo}
              alt="logo"
              ref={img => (this.footerLogo = img)}
            />
          </div>
          <div className="sections-container">
            <div
              className="about-section"
              ref={div => (this.footerAbout = div)}
            >
              <div
                className="dropdown"
                onClick={() => this.trigger(1)}
              >
                <h4>About</h4>
                <i className="fa fa-angle-down mobile" />
              </div>
              <ul
                className={`footer-link-list ${
                  selectedIndex === 1 ? 'show' : 'hide'
                  }`}
              >
                <li>
                  <Link to="/about">About Urbanite</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li style={{ display: 'none' }}>
                  <Link to="/sitemap">Site Map</Link>
                </li>
              </ul>
            </div>
            <div
              className="policies-section"
              ref={div => (this.footerPolicies = div)}
            >
              <div
                className="dropdown"
                onClick={() => this.trigger(2)}
              >
                <h4>Policies</h4>
                <i className="fa fa-angle-down mobile" />
              </div>
              <ul
                className={`footer-link-list ${
                  selectedIndex === 2 ? 'show' : 'hide'
                  }`}
              >
                <li>
                  <Link to="/cancellation-policy">Cancellation Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Guest Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Website Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div
              className="social-section"
              ref={div => (this.footerSocial = div)}
            >
              <div
                className="dropdown"
                onClick={() => this.trigger(3)}
              >
                <h4>Social</h4>
                <i className="fa fa-angle-down mobile" />
              </div>
              <ul
                className={`footer-link-list ${
                  selectedIndex === 3 ? 'show' : 'hide'
                  }`}
              >
                <li>
                  <a
                    href="http://www.facebook.com/urbanite.io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.instagram.com/urbanite.io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="http://twitter.com/urbanite_io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.linkedin.com/company/urbanitetechnologies/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.com.au/urbanite_io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Pinterest
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.houzz.com.au/pro/urbanitetech"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Houzz
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="contact-section"
              ref={div => (this.footerContact = div)}
            >
              <div
                className="dropdown"
                onClick={() => this.trigger(4)}
              >
                <h4>Contact</h4>
                <i className="fa fa-angle-down mobile" />
              </div>
              <ul
                className={`footer-link-list ${
                  selectedIndex === 4 ? 'show' : 'hide'
                  }`}
              >
                <li>822 George St</li>
                <li>Chippendale</li>
                <li>Sydney 2008</li>
                <li>Australia</li>
                <li className="phone">1300 872 260</li>
                <li className="mail">
                  <a href="mailto:contact@urbanite.io">contact@urbanite.io</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default reduxForm({
  form: 'Footer',
  destroyOnUnmount: false
})(Footer);

import $ from 'jquery';

export const fetchHighlights = (success) => {
  $.ajax({
    method: 'GET',
    url: '/api/highlights',
    success,
    error: () => console.log('error during getting highlights')
  });
};

export const fetchAmenities = (success) => {
  $.ajax({
    method: 'GET',
    url: '/api/amenities',
    success,
    error: () => console.log('error during getting amenities')
  });
};

export const fetchExtras = (success) => {
  $.ajax({
    method: 'GET',
    url: '/api/extras',
    success,
    error: () => console.log('error during getting extras')
  });
};

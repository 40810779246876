import {
  UPDATE_FILTER_OPTIONS,
} from '../actions/filter_actions';

const initialState = {
  isFavorite: false,
  filterOptions: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_OPTIONS: {
      return {
        ...state,
        isFavorite: action.isFavorite,
        filterOptions: action.isFavorite
          ? action.filterOptions
          : {},
      };
    }
    default:
      return state;
  }
};

export default reducer;

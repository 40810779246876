import { RECEIVE_PROPERTIES } from '../actions/listing_actions';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROPERTIES:
      return action.properties;
    default:
      return state;
  }
};

export default reducer;

export const REQUEST_ID_VERIFY = 'REQUEST_ID_VERIFY';
export const RECEIVE_ID_INFO_SUCCESS = 'RECEIVE_ID_INFO_SUCCESS';
export const RECEIVE_ID_INFO_FAILURE = 'RECEIVE_ID_INFO_FAILURE';
export const RESET_ID_INFO = 'RESET_ID_INFO';

export const requestIDVerify = imgData => ({
  type: REQUEST_ID_VERIFY,
  imgData
});

export const receiveIdInfoSuccess = idCardInfo => ({
  type: RECEIVE_ID_INFO_SUCCESS,
  idCardInfo
});

export const receiveIdInfoFailure = () => ({
  type: RECEIVE_ID_INFO_FAILURE,
});

export const resetIdInfo = () => ({
  type: RESET_ID_INFO,
});

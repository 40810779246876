import React from 'react';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import findIndex from 'lodash/findIndex';

import LoginForm from '../onboarding/login_form';
import SignupForm from '../onboarding/signup_form';
import { loginModalStyle, signupModalStyle } from './modal_styles';
import { MONTHS } from '../../util/month_list';
import {
  getToken,
  XLARGE_DEVICE_MIN_WIDTH,
} from '../../util/common_util';

import { logout, clearErrors, receiveErrors } from '../../actions/session_actions';
import { requestProperties } from '../../actions/listing_actions';
import { requestBookings } from '../../actions/booking_actions';
import { openLogin, closeLogin, openSignup, closeSignup } from '../../actions/modal_actions';
import OverlayBox from '../_commons/overlay';
import UserIcon from '../../assets/svg/user.svg';
import HamburgerIcon from '../../assets/hamburger_icon.png';

import '../../style/_navbar.scss';

const NAV_ITEMS = [
  { path: '/list-your-home', label: 'List Your Home' },
  { path: '/guest-service', label: 'Guest Service' },
  { path: '/journal', label: 'Journal' },
];

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      isMobileMenu: false,
      selectedIndex: -1,
    });

    this.container = null;

    this.props.history.listen((location) => {
      this.setState({ isMobileMenu: false });

      this.setMenuItemSelection(location);
    });

    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  componentDidMount() {
    const {
      currentUser,
      requestBookings,
      requestProperties,
    } = this.props;

    this.setMenuItemSelection(this.props.history.location);
    document.removeEventListener('click', this.closeNav);

    if (currentUser) {
      requestBookings();
      requestProperties();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeNav);
  }

  setMenuItemSelection(location) {
    const selectedIndex = findIndex(NAV_ITEMS, item => location.pathname.startsWith(item.path));
    this.setState({ selectedIndex });
  }

  openNav() {
    document.addEventListener('click', this.closeNav);
    document.body.style.overflowY = 'hidden';
  }

  closeNav() {
    document.removeEventListener('click', this.closeNav);
    this.setState({ isMobileMenu: false });
    document.body.style.overflowY = 'scroll';
  }

  openLoginModal = () => {
    this.setState({ isMobileMenu: false });
    this.props.openLogin();
  }

  openSignupModal = () => {
    this.setState({ isMobileMenu: false });
    this.props.openSignup();
  }

  toBookingDetail = e =>
    this.props.history.push(`/bookings/${e.currentTarget.dataset.id}`);

  toListingDetails = e =>
    this.props.history.push(`/listings/${e.currentTarget.dataset.id}`);

  toPropertyDetails = e =>
    this.props.history.push(`/properties/${e.currentTarget.dataset.id}`);

  getCurrentBookings = (bookings) => {
    const today = new Date();
    return (bookings.filter((booking) => {
      const bookDate = new Date(booking.end_date);
      return bookDate.getTime() >= today.getTime();
    }));
  }

  loggedOutButtons = () => (
    <div className="nav-buttons-container">
      <ul className="nav-buttons group">
        <li
          className="nav-signup"
          onClick={this.openSignupModal}
        >
          Sign Up
        </li>
        <li
          className="nav-login"
          onClick={this.openLoginModal}
        >
          <span>Log In</span>
          <img alt="" className="user-icon" src={UserIcon} />
        </li>
      </ul>
    </div>
  )

  loggedInButtons = () => {
    const { bookings, properties } = this.props;

    const currentBookings = this.getCurrentBookings(bookings);

    const bookingsList = currentBookings && currentBookings.map((booking) => {
      const start = new Date(booking.start_date);
      const end = new Date(booking.end_date);

      return (
        <li key={booking.id} onClick={this.toBookingDetail} data-id={booking.id}>
          <div>
            <p className="booking-city">
              {booking.listing.city}
            </p>
            <p className="booking-dates">
              {MONTHS[start.getMonth()]} {start.getDate()} - {MONTHS[end.getMonth()]} {end.getDate()}
            </p>
          </div>
          <img src={booking.listing.image} alt="booking" className="booking-dropdown-image" />
        </li>
      );
    });

    const propertyList = properties && properties.map(property => (
      <li key={property.id} onClick={this.toPropertyDetails} data-id={property.id}>
        <div className="property-name">
          <p>
            {property.name}
          </p>
        </div>
        <img src={property.image} alt="booking" className="booking-dropdown-image" />
      </li>
    ));

    return (
      <div className="nav-buttons-container">
        <ul className="nav-buttons group">
          {!window.J15 && bookings.length > 0 && (
            <li className="nav-booking" id="nav-trips-li">
              <button onClick={() => this.gotoList('/booking-list')}>{bookings.length > 1 ? 'Bookings' : 'Booking'}</button>
              <ul className="trips-dropdown">
                {bookingsList}
                {bookings.length > this.getCurrentBookings(bookings).length &&
                  <li className="booking-last"><Link to="/past-bookings">Past Bookings</Link></li>}
              </ul>
            </li>
          )}
          {!window.J15 && properties.length > 0 && (
            <li className="nav-property" id="nav-trips-li">
              <button onClick={() => this.gotoList('/property-list')}>{properties.length > 1 ? 'Properties' : 'Property'}</button>
              <ul className="trips-dropdown">
                {propertyList}
              </ul>
            </li>
          )}
          <li className="nav-user-info" id="nav-user-prof">
            <img alt="" className="user-icon" src={UserIcon} onClick={() => this.goto('/account-settings/account')} />
          </li>
        </ul>
      </div>
    );
  }

  handleLogout = () => {
    this.setState({ isMobileMenu: false });
    this.returnToHome();
    this.props.logout();
  }

  returnToHome = () => {
    this.setState({ selectedIndex: -1 });
    this.props.history.push('/');
  }

  goto = (route) => {
    const { history } = this.props;
    this.setState({ isMobileMenu: false });

    history.push(route);
  }

  gotoList = (route) => {
    if (this.container) {
      if (this.container.offsetWidth < XLARGE_DEVICE_MIN_WIDTH) {
        this.goto(route);
      }
    }
  }

  onTriggerMenu = () =>
    this.setState({ isMobileMenu: !this.state.isMobileMenu });

  render() {
    const { isMobileMenu } = this.state;
    const { modals } = this.props;

    // const buttons = currentUser || getToken()
    //   ? this.loggedInButtons(currentUser)
    //   : this.loggedOutButtons();

    return (
      <nav className="navbar" ref={(c) => { this.container = c; }}>
        <div className={`navbar-container ${isMobileMenu ? 'visible' : 'invisible'}`}>
          <div className="nav-logo">
            <div className="logo-image" onClick={this.returnToHome} />
            <img
              className="fa-bars"
              src={HamburgerIcon}
              onClick={this.onTriggerMenu}
              alt=""
            />
          </div>
          <div className="menu-wrapper">
            <ul className="nav-buttons group menus">
              <OverlayBox />
              <li>
                <a href="https://www.booking.urbanite.io/en/1219244/all-properties">
                  Book a Home
                </a>
              </li>
              {NAV_ITEMS.map((item, index) => (
                <li
                  key={index}
                  className={this.state.selectedIndex === index ? 'selected' : ''}
                  onClick={() => this.goto(item.path)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
            <div className="nav-buttons-container" />
            {/* buttons */}
          </div>
          <Modal
            isOpen={modals.openLogin}
            onRequestClose={this.props.closeLogin}
            contentLabel=""
            style={loginModalStyle}
            className="login-modal-wrapper"
          >
            <LoginForm closeModal={this.props.closeLogin} />
          </Modal>

          <Modal
            isOpen={modals.openSignup}
            onRequestClose={this.props.closeSignup}
            contentLabel=""
            style={signupModalStyle}
            className="signup-modal-wrapper"
          >
            <SignupForm closeModal={this.props.closeSignup} />
          </Modal>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  location: state.location,
  bookings: state.bookings,
  properties: state.properties,
  modals: state.modals,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    logout,
    clearErrors,
    receiveErrors,
    requestBookings,
    requestProperties,
    openLogin,
    closeLogin,
    openSignup,
    closeSignup,
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navbar));

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import SessionReducer from './session_reducer';
import ListingReducer from './listing_reducer';
import ListingsReducer from './listings_reducer';
import properties from './properties_reducer';
import FiltersReducer from './filters_reducer';
import LocationReducer from './location_reducer';
import ListingformReducer from './listingform_reducer';
import BookingReducer from './booking_reducer';
import ReviewReducer from './review_reducer';
import idCard from './idcard_reducer';
import modals from './modals';
import utilities from './utilities_reducer';
import journals from './journals_reducer';
import jobs from './job_reducer';
import faq from './faq_reducer';
import FilterOptionsReducer from './filter_options_reducer';
import ProspectsReducer from './prospects_reducer';
import page from './static_page_reducer';

const RootReducer = combineReducers({
  session: SessionReducer,
  listing: ListingReducer,
  listings: ListingsReducer,
  prospects: ProspectsReducer,
  properties,
  filters: FiltersReducer,
  filterOptions: FilterOptionsReducer,
  location: LocationReducer,
  listingFields: ListingformReducer,
  bookings: BookingReducer,
  reviews: ReviewReducer,
  page,
  form: formReducer,
  idCard,
  modals,
  utilities,
  journals,
  jobs,
  faq,
});

export default RootReducer;

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import $ from 'jquery';
import 'semantic-ui-css/semantic.min.css';
import 'font-awesome/css/font-awesome.css';
import './index.scss';
import './polyfills';

import configureStore from './store/store';
import Root from './components/root';

// import { enableMocking } from './helpers/mocking';
import { getToken } from './util/common_util';

const baseUrl = process.env.REACT_APP_BASE_URL;
let store;

if (false) {
  // enableMocking($, window);
}

window.J15 = true;

$.ajaxSetup({
  beforeSend(xhr, options) {
    options.url = baseUrl + options.url;

    if (getToken()) {
      xhr.setRequestHeader('Authorization', getToken());
    }
  },
  xhrFields: {
    withCredentials: true
  },
  crossDomain: true
});

document.addEventListener('DOMContentLoaded', () => {
  renderWithUser(null);
});

const renderWithUser = (user) => {
  if (user) {
    const initialState = {
      session: {
        currentUser: user,
        errors: []
      }
    };
    store = configureStore(initialState);
  } else {
    store = configureStore();
  }

  const root = document.getElementById('root');
  Modal.setAppElement(document.body);
  ReactDOM.render(<Root {...store} />, root);
};

import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducer from '../reducers/root_reducer';
import RootMiddleware from '../middleware/root_middleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filterOptions']
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const configureStore = (preloadedState = {}) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(RootMiddleware)
  );

  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;

import { applyMiddleware } from 'redux';
import SessionMiddleware from './session_middleware';
import ListingMiddleware from './listing_middleware';
import BookingMiddleware from './booking_middleware';
import ReviewMiddleware from './review_middleware';
import UtilityMiddleware from './utility_middleware';
import JournalMiddleware from './journal_middleware';
import JobMiddleware from './job_middleware';
import FAQMiddleware from './faq_middleware';
import ProspectMiddleware from './prospect_middleware';
import QueuerMiddleware from './queuer_middleware';
import StaticPageMiddleware from './static_page_middleware';

const RootMiddleware = applyMiddleware(
  SessionMiddleware,
  ListingMiddleware,
  BookingMiddleware,
  ReviewMiddleware,
  UtilityMiddleware,
  JournalMiddleware,
  JobMiddleware,
  FAQMiddleware,
  ProspectMiddleware,
  QueuerMiddleware,
  StaticPageMiddleware,
);

export default RootMiddleware;

import { RECEIVE_SINGLE_LISTING } from '../actions/listing_actions';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SINGLE_LISTING:
      return action.listing;
    default:
      return state;
  }
};

export default reducer;
